<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        
        <h5 class="ml-2 text-secondary font-weight-light"><span class="fa fa-prescription-bottle-alt"></span> Collection Du lait : <span class="ml-2 text-success"> {{UiCentre}} - {{UiPeriod}} </span> </h5>
        
        <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">De : <span class="d-inline text-info"> {{dateDebutL}} </span> Au : <span class="d-inline text-info"> {{dateFinL}} </span> </div>

    </div>

    <div class="col">
         

                <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="NewBon">
                            <i class="fa fa-plus-square"></i>
                    <span> Autre Centre</span> 
                </b-button>

    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">
                <div class="p-0">
                        <b-button v-b-toggle.collap-agri.collap-form size="lg" class=" ml-0 mr-0 pl-1 pr-1 float-left text-secondary" variant="link">
                        <i class="fa fa-bars"></i>                
                        </b-button> 
                </div>


                <b-collapse id="collap-agri" visible  class="col-3 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">
                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchAgri"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par CIN</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filteragrie" :key="item.id" button class="p-1" variant="info" @click="agriculteur_click(item.id)">
                                 {{ item.nom }} -  {{ item.cin }} 
                                 <p class="mb-1"> <small>Quota : {{item.quota}}</small> </p>
                            </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

<div class="col h-100 overflow-auto mt-2 mr-2 ml-1 ">

                <b-collapse id="collap-form" visible  >
                            <div v-show="itemFormLait.agriculteur_id" class="rounded-lg  border border-text-black-50 mb-2">
                                       
                                        <div class=" d-flex justify-content-center text-primary">
                                        <i class="fas fa-user-circle fa-2x"></i>
                                        </div>
                                       
                                        <div class=" d-flex justify-content-center p-1 text-primary">
                                                <h5 class="font-weight-light" >{{UiAgriculteur}} -<span class="h6 font-weight-light text-secondary">Quota: {{currentQota}} </span> </h5>
                                        </div>


                            <div class="input-group col mb-2">

                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Lait Récupéré (L) </span>
                            </div>

                            <input ref="qte_field" @change="laitTotal_click" type="number" min="0" v-model="itemFormLait.totalLait" placeholder="Total Lait Récupéré" id="totalLait" name="totalLait" class="form-control "/>

                            <div class="input-group-append">
                                        <b-button @click="laitTotal_click" size="sm" class=" ml-0 mr-0 pl-2 pr-2 float-left text-white" variant="secondary">
                                        <i class="fa fa-calculator"></i>                
                                        </b-button> 
                            </div>
                            </div>



                            </div>

                            <form @submit.prevent="handleSubmit" >


                            <div class="row ">
                                
                            <div class="form-group col m-0">
                                <label for="quota" class="form-control-sm  m-0">Quota</label>
                                <input type="text" v-model="itemFormLait.quota" id="quota" name="quota" class="form-control form-control-sm"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemFormLait.quota.$error }" />
                                <div v-if="isSubmitted && !$v.itemFormLait.quota.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                <div v-if="isSubmitted && !$v.itemFormLait.quota.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>

                            <div class="form-group col m-0">
                                <label for="surquota" class="form-control-sm m-0">Sur Quota</label>
                                <input type="text" v-model="itemFormLait.surquota" id="surquota" name="surquota" class="form-control form-control-sm"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemFormLait.quota.$error }" />
                                <div v-if="isSubmitted && !$v.itemFormLait.surquota.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                <div v-if="isSubmitted && !$v.itemFormLait.surquota.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>
                            </div>



                            <div class="form-group ">
                                <label for="date"  class="form-control-sm m-0">Date</label>
                                    <b-form-datepicker
                                    class="form-control-sm m-0"
                                    id="datepicker-dateformat2"
                                    placeholder="Choose a date"
                                    v-model="itemFormLait.date"
                                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                    locale="fr"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemFormLait.date.$error }" />
                                <div v-if="isSubmitted && !$v.itemFormLait.date.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                            </div>


                            <div class="form-group">

                            <textarea name="description" placeholder="Description" id="description"  rows="2" class="form-control form-control-sm" v-model="itemFormLait.description"></textarea>
                            </div>

                            <div class="custom-control custom-switch mb-2">
                            <input disabled type="checkbox"
                            class="custom-control-input" 
                            id="etat"
                                    v-model="itemFormLait.matin"
                                    name="etat"
                                    value=true
                                    unchecked-value=false

                            >

                            <label class="custom-control-label" for="etat">Matin</label>
                            </div>

                            <div class="form-group">
                                <button type="submit" class="btn btn-secondary btn-block">Ajouter</button>
                            </div>

                            </form>
                </b-collapse>

            <div class="row mb-2">
            <h6 class="col d-flex justify-content-center ml-3 font-weight-lighter text-info">  Total Quota : {{total_quota}} L</h6> 
            <h6 class="col d-flex justify-content-center ml-3 font-weight-lighter text-info">  Total Sur Quota :{{total_surquota}} L</h6> 
            <h6 class="col d-flex justify-content-center ml-3 font-weight-lighter text-success">  Total  :{{total_lait}} L</h6> 

            </div>


            

                      <b-table 
        sort-icon-left
        small 
        responsive 
        :fields="fieldsagrie" 
        :items="laitLists"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


         

</div>

  </div>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
 props: ['idCentre','dateDebutL','dateFinL','matin','nCentre'],

 
    computed: {
    total_quota: function() {
      return this.laitLists.reduce((a, b) => +a + +b.quota, 0);
    },

      total_surquota: function() {
      return this.laitLists.reduce((a, b) => +a + +b.surquota, 0);
    },
    total_lait: function() {
      return this.laitLists.reduce((a, b) => +a + +Number(b.quota) + Number(b.surquota) , 0);
    },

    filteragrie() {
            return this.agriculteursList.filter(agrie => {

            if(this.isCINBarreSearch)
            {
                if(agrie.cin)
                {
                return agrie.cin.toLowerCase().includes(this.serchAgri.toLowerCase())
                }

            }
            else
            {

                if(agrie.nom)
                {
                return agrie.nom.toLowerCase().includes(this.serchAgri.toLowerCase())
                }


            }

      })
    }
    },
    mounted(){

            if(this.idCentre)
            {
            this.fetchListAgriculteurs(this.idCentre)
            this.itemFormLait.centre_id = this.idCentre

            this.UiCentre= this.nCentre

if(this.matin)
{
this.UiPeriod = "Matin"
this.itemFormLait.matin = this.matin
}
else
{
 this.UiPeriod = "Soir"
 this.itemFormLait.matin = false
}

            }
            else
            {
            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible de charger les données !!'
            })
            }

    },
    data () {
		return {
            fieldsagrie: [
                {key: 'quota', label: 'Quota'},
                {key: 'surquota', label: 'Sur Quota'},
                {key: 'is_matin', label: 'Période', 
                formatter: (value) => {
                 return value ? 'Matin' : 'Soir'
                }
                , sortable: true},
                {key: 'date', label: 'Date'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            UiPeriod:'',
            UiCentre:'',
            
            UiAgriculteur:'',
            currentQota:0,
            isCINBarreSearch: false,
            serchAgri:'',
            agriculteursList:[],
            laitLists: [],
            itemFormLait: {
                    totalLait:0,
                    quota:'',
                    surquota:'',
                    matin:true,
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    agriculteur_id:'',
                    centre_id:''
            },
            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemFormLait: {
                quota: {
                    required,
                    decimal
                },
                surquota: {
                    required,
                    decimal
                },
                matin: {
                    required
                },
                date: {
                    required
                },
                agriculteur_id: {
                    required
                },
                centre_id: {
                    required
                }
            }

    },
    methods:{
        laitTotal_click()
        {          
                    if(this.itemFormLait.totalLait)
                    {


                                                let curntQota =Number(this.currentQota)
                                                let laitotal =Number(this.itemFormLait.totalLait)

                                            if(curntQota < laitotal)
                                            {
                                            this.itemFormLait.quota = curntQota
                                            this.itemFormLait.surquota = laitotal -curntQota
                                            }
                                            else
                                            {

                                            this.itemFormLait.quota = laitotal
                                            this.itemFormLait.surquota = 0
                                            }
                                
                    }



        },
        fetchListAgriculteurs(id_agri) {
                    this.isLoading=true;
                    this.$http.get('/agriCentreMin/'+id_agri)
                    .then((result) => {
                                this.agriculteursList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        agriculteur_click(id_agri)
        {

            let agr = this.agriculteursList.findIndex(item => item.id === id_agri)
            this.itemFormLait.agriculteur_id=this.agriculteursList[agr].id
            //crrentQota
            this.currentQota=this.agriculteursList[agr].quota
            //UI agriculteur
            this.UiAgriculteur=this.agriculteursList[agr].nom



          this.fetchListLaits(id_agri)

            this.itemFormLait.totalLait=''
          this.$refs.qte_field.focus();
        },

        fetchListLaits(id_agri)
        {
                    this.isLoading=true;
                    this.$http.get('/LaiListDate/'+id_agri+'/'+this.dateDebutL+'/'+this.dateFinL)
                    .then((result) => {
                                this.laitLists = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                        if(!this.idCentre)
                        {
                                                this.flashMessage.show({
                                                status: 'info',
                                                title: 'Vérifiez les Données !! .. Actualisez et Réessayez',
                                                })
                        }
                        else
                        {
                            this.isLoading=true;

                                this.$http.post('/laitColAgris',{

                                quota:this.itemFormLait.quota,
                                surquota:this.itemFormLait.surquota,
                                is_matin:this.itemFormLait.matin,
                                date:this.itemFormLait.date,
                                description:this.itemFormLait.description,
                                agriculteur_id:this.itemFormLait.agriculteur_id,
                                centre_id:this.itemFormLait.centre_id
                                    })
                                .then(response => {

                                    this.isLoading=false;
                                    this.laitLists.unshift(response.data.data);

                                    //effacer les champs:
                                    this.itemFormLait.quota = ''
                                    this.itemFormLait.surquota = ''

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                            
                        }            

                }

        },
        del_click(itemA){



            this.isLoading=true;

            this.$http.delete('/laitColAgris/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.laitLists.findIndex(item => item.id === itemA.id)

                    this.laitLists.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },

        NewBon()
        {
                    this.$router.push({ 
                        name: 'agriLaitAdd'
                    });
        },

},
components: {
              Loading      
}


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>