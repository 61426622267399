<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
    <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Services/Produits Agriculteur : <span class="ml-2 text-success">  {{nAgri}} - {{nMois}} </span> </h5>

        
        <div class="mt-1 ml-2 d-inline text-secondary">Centre : <span class="d-inline text-info"> {{nCentre}} </span>  </div>

        <div class="mt-1 ml-2 d-inline text-secondary"> - Dépôt : <span class="d-inline text-info"> {{nDepot}} </span>  </div>

    </div>



</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">
<div class="p-0">
        <b-button v-b-toggle.collap-categ.collap-articl.collap-form size="lg" class=" m-0 pl-1 pr-1 float-left text-secondary" variant="link">
        <i class="fa fa-bars"></i>                
        </b-button> 
</div>
  
                <b-collapse id="collap-categ" visible  class="col-2 h-100 mr-3 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto">
                            <div class="card-header  bg-success border-success p-1">
                                            <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                           
                                            <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none" variant="link">
                                            <i class="fa fa-list-alt mr-1"></i>  
                                            Tous Les Articles              
                                            </b-button> 
                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="cat_click(item.id)">
                                    {{ item.nom }} 
                                    <p> <small>{{item.description}}</small> </p>
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <b-collapse id="collap-articl" visible  class="col-2 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">
                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCodeBarreSearch"
                                                v-model="isCodeBarreSearch"
                                                name="isCodeBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCodeBarreSearch">Code Barre</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-1" variant="info" @click="art_click(item.id)">
                                 {{ item.ref }}
                                 <p> <small>{{item.description}}</small> </p>
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">


<b-collapse id="collap-form" visible>


            <form @submit.prevent="handleSubmit" >



            <div class="form-group">
                    <b-form-datepicker
                     class=" form-control form-control-sm mb-0"
                    id="datepicker-dateformat2"
                    placeholder="Choose a date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.date.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.date.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="row">
            <div class="form-group col">
                <label for="nom" class="form-control-sm mb-0">Référence :</label>
                <input type="text" v-model="itemForm.nom" id="nom" name="nom" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div class="form-group col">
                <label for="prix" class="form-control-sm mb-0">Prix TTC : </label>
                <input type="text" v-model="itemForm.prix" id="prix" name="prix" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.prix.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.prix.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            </div>

            <div class="row"> 
            <div class="form-group col">

                <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                <select class="form-control form-control-sm" id="lot_id" v-model="itemForm.lot_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_id.$error }">
                <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemForm.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>


            <div class="form-group col">
                <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                <input ref="qte_field" type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            </div>




            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
            </div>

            </form>
</b-collapse>


            <div class="row mb-2">

            <h5 class="col d-flex justify-content-center ml-3 font-weight-lighter text-success">  Total  :{{total_prix}} DH</h5> 

            </div>
            

        <b-table 
        sort-icon-left
        small 
        responsive 
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


         

</div>

  </div>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
 props: ['idAgri','nAgri','idCentre','idDepot','nDepot','nCentre','mois','nMois'],

 
    computed: {
    total_prix: function() {
      return this.ArticlesList.reduce((a, b) => +a + +b.qte * b.prix , 0);
    },
    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code_bar)
                {
                return article.code_bar.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.ref.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){

            if(this.idAgri)
            {
            this.fetchCategorieList()
            this.fetchListArts()
            }
            else
            {
            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données de ce bon'
            })
            }

    },
    data () {
		return {
            fieldsArticle: [
                {key: 'nom', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'prix', label: 'Prix'},
                {key: 'date', label: 'Date'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            ListLots:[],
            ListClients:[],
            isCodeBarreSearch: false,
            searchCateg:'',
            serchArt:'',
            ArticlesList:[],
            listCat: [],
            listArt: [],
            itemForm: {
                    nom:'',
                    code_bar:'',
                    qte:'',
                    prix:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    article_id:'',
                    lot_id:'',},
            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemForm: {
                nom: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                prix: {
                    required,
                    decimal
                },
                date: {
                    required
                },
                article_id: {
                    required
                },
                lot_id: {
                    required
                }
            }

    },
    methods:{

        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        cat_click(id_cat)
        {
                    this.isLoading=true;
                    this.$http.get('/articlesCat/'+id_cat)
                    .then((result) => {
                                this.listArt = result.data.data

                                
                                this.isLoading=false;


                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        art_click(id_art)
        {

            let art = this.listArt.findIndex(item => item.id === id_art)
            this.itemForm.nom=this.listArt[art].ref
            this.itemForm.code_bar=this.listArt[art].code_bar
            this.itemForm.qte=1
            this.itemForm.article_id=this.listArt[art].id
            this.itemForm.prix=this.listArt[art].prix_vent

            this.fetchLots(id_art)
            
            
            this.$refs.qte_field.focus();
        },
        fetchLots(id_art) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.idDepot)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemForm.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemForm.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            //lot desti


        },
        fetchListArts()
        {
                    this.isLoading=true;
                    this.$http.get('/servicesParAgri/'+this.idAgri+'/'+this.mois)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){


                //set nom lot
                if(this.itemForm.lot_id)
                {
                let am = this.ListLots.findIndex(item => item.id === this.itemForm.lot_id)
                this.itemForm.nom_lot= this.ListLots[am].nom
                }

                let mm = new Date(this.itemForm.date).getMonth()
                mm++
                if(mm==this.mois)
                {


                this.add_service()
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Date Hors Mois',
                                message: 'Sélectionnez une date dans le mois sélectionné .. Réessayez !!'
                                })
                }



        },
        add_service()
        {
            

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/serviceAgris',{
                            nom:this.itemForm.nom,
                            qte:this.itemForm.qte,
                            prix:this.itemForm.prix,
                            date:this.itemForm.date,
                            description:this.itemForm.description,
                            agriculteur_id: this.idAgri,
                            centre_id:this.idCentre,
                            article_id:this.itemForm.article_id,
                            depot_id:this.idDepot,
                            lot_id:this.itemForm.lot_id

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }
        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/serviceAgris/'+itemA.id)
                .then(response => {
               this.isLoading=false; 

        console.log(response)

               if(response.data.data =="ok")
               {

  
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
               }
               else
               {
                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
               }

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        AfficherTousArts()
        {
        this.cat_click(0)
        }
        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>