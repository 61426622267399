<template>
<div>
  
               

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center ">  

            <div class="col">
            <h4 class=" font-weight-lighter text-info m-3 float-left"> <span class="fa fa-user-circle"></span> Agriculteurs    </h4> 

                <b-button pill size="sm"  class="mt-3 mb-3 float-left" variant="outline-success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

            </div>
            
            <div class="col">
                <div class="input-group col">
                                    <select class="form-control border border-ligth " id="selected_centre" v-model="selected_centre">
                                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            <option value=0>Tous</option>

                                     </select>


                                    <div class="input-group-prepend">
                                        <button @click="click_AgriList" class="btn  btn-light  border border-ligth rounded-right " type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                </div>
            </div>
                                
              <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col ml-4 mr-4"></b-form-input>




            </div>



        <b-table 
        id="main-table"

        sort-icon-left

        head-variant="light"
        :filter="filter"
        :fields="fields" 
        :items="agris"
        :per-page="perPage"
        :current-page="currentPage"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mb-1  float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>


                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="banqModal" title="Agriculteur" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">



            <div class="row">
            <div class="form-group col">
                <label for="nom">Nom Et Prénom FR:</label>
                <input type="text" v-model="agriForm.nom" id="nom" name="nom" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.agriForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.agriForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

                    <div class="form-group col">
                        <label for="nom_ar">Nom Et Prénom AR:</label>
                        <input type="text" v-model="agriForm.nom_ar" id="nom_ar" name="nom_ar" class="form-control form-control-sm"/>

                    </div>
            </div>



            <div class="row">
                
            <div class="form-group col">
                <label for="cin">CIN / ID :</label>
                <input type="text" v-model="agriForm.cin" id="cin" name="cin" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.agriForm.cin.$error }" />
                <div v-if="isSubmitted && !$v.agriForm.cin.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>
            <div class="form-group col">
                <label for="quota">Quota :</label>
                <input type="text" v-model="agriForm.quota" id="quota" name="quota" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.agriForm.quota.$error }" />
                <div v-if="isSubmitted && !$v.agriForm.quota.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.agriForm.quota.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
            </div>


            <div class="row">
                        <div class="form-group col">
                            <label for="centre">Centre :</label>
                            <select class="form-control form-control-sm" id="centre" v-model="agriForm.centre_id"
                            :class="{ 'is-invalid': isSubmitted && $v.agriForm.centre_id.$error }">
                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                        </div>

                        <div class="form-group col">
                            <label for="banque">Banque :</label>
                            <select class="form-control form-control-sm" id="banque" v-model="agriForm.banque_id"
                            :class="{ 'is-invalid': isSubmitted && $v.agriForm.banque_id.$error }">
                            <option v-for="item in banqs" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>
                        </div>

            </div>
            <div class="row">
                        <div class="form-group col">
                            <label for="tel">Téléphone :</label>
                            <input type="text" v-model="agriForm.tel" id="tel" name="tel" class="form-control form-control-sm" />

                        </div>


                        <div class="form-group col">
                            <label for="rib">RIB</label>
                            <input type="text" v-model="agriForm.rib" id="rib" name="rib" class="form-control form-control-sm" />

                        </div>
            </div>
            <div class="form-group">
            <label for="adresse">Adresse :</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control form-control-sm" v-model="agriForm.adresse"></textarea>
            </div>






            <div class="row">

                    <div class="col">

                        
                                <div class="custom-control custom-switch">
                                <input type="checkbox"
                                class="custom-control-input" 
                                id="abonne"
                                        v-model="agriForm.abonne"
                                        name="abonne"
                                        value=true
                                        unchecked-value=false

                                >

                                <label class="custom-control-label" for="abonne">Adhérent</label>
                                </div>

                    </div>


                    <div class="col">

                        
                            <div class="custom-control custom-switch">
                            <input type="checkbox"
                            class="custom-control-input" 
                            id="etat"
                                    v-model="agriForm.etat"
                                    name="etat"
                                    value=true
                                    unchecked-value=false

                            >

                            <label class="custom-control-label" for="etat">Actif</label>
                            </div>
                            </div>  




                    </div>

                            <div class="form-group">
                                <button type="submit" class="btn mt-3 btn-primary btn-block">{{ModalType}}</button>
                            </div>

        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {

    mounted(){


          this.fetchFormBanqs()
    },

    computed: {
      rows_pagin() {
        return this.agris.length
      }
    },
	data () {
		return {
            selected_centre:'',
            filter: null,
            perPage: 10,
            currentPage: 1,
            banqs:[],
            centres:[],
            agris: [],
            agriForm: {
                    id:'',
                    nom:'',
                    nom_ar:'',
                    cin:'',
                    quota:'',
                    tel:'',
                    adresse:'',
                    rib:'',
                    etat:'',
                    abonne:'',
                    centre_id:'',
                    banque_id:''
                    },
            fields: [
				{key: 'cin', label: 'CIN/ID'},
                {key: 'nom', label: 'Nom & Prénom FR', sortable: true},
                {key: 'nom_ar', label: 'Nom & Prénom AR', sortable: true},
                {key: 'nom_centre', label: 'Centre', sortable: true},
                {key: 'quota', label: 'Quota', sortable: true},
                {key: 'etat', label: 'Etat', 
                formatter: (value) => {
                 return value ? 'Actif' : 'Inactif'
                }, sortable: true},
                {key: 'abonne', label: 'Adhérent', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            agriForm: {
                nom: {
                    required
                },
                cin: {
                    required
                },
                quota: {
                    required,
                    decimal
                },
                centre_id: {
                    required
                },
                banque_id: {
                    required
                }
            }
    },
    methods :{


        banquestore(){
           this.isLoading=true;


             this.$http.post('/agriculteurs',{
            cin:this.agriForm.cin,
            nom:this.agriForm.nom,
            nom_ar:this.agriForm.nom_ar,
            tel:this.agriForm.tel,
            quota:this.agriForm.quota,
            adresse:this.agriForm.adresse,
            rib:this.agriForm.rib,
            etat:this.agriForm.etat,
            abonne:this.agriForm.abonne,
            banque_id:this.agriForm.banque_id,
            centre_id:this.agriForm.centre_id
                })
                .then(response => {

                    this.isLoading=false;
                    this.agris.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/agriculteurs/'+this.agriForm.id,{
                cin:this.agriForm.cin,
                nom:this.agriForm.nom,
                nom_ar:this.agriForm.nom_ar,
                tel:this.agriForm.tel,
                quota:this.agriForm.quota,
                adresse:this.agriForm.adresse,
                rib:this.agriForm.rib,
                etat:this.agriForm.etat,
                abonne:this.agriForm.abonne,
                banque_id:this.agriForm.banque_id,
                centre_id:this.agriForm.centre_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('banqModal')

                    let index = this.agris.findIndex(item => item.id === this.agriForm.id)
                    this.agris.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/agriculteurs/'+this.agriForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.agris.findIndex(item => item.id === this.agriForm.id)
                    this.agris.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.agriForm.id='',
        this.agriForm.cin='',
        this.agriForm.nom='',

        this.agriForm.nom_ar='',

        this.agriForm.tel='',
        this.agriForm.quota=0,
        this.agriForm.adresse='',
        this.agriForm.rib='',
        this.agriForm.etat=true,
        this.agriForm.abonne=true,
        this.agriForm.banque_id='',
        this.agriForm.centre_id=''


        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {

                this.agriForm.id=item.id,
                this.agriForm.cin=item.cin,
                this.agriForm.nom=item.nom,

                this.agriForm.nom_ar=item.nom_ar,

                this.agriForm.tel=item.tel,
                this.agriForm.quota=item.quota,
                this.agriForm.adresse=item.adresse,
                this.agriForm.rib=item.rib,
                this.agriForm.etat=item.etat,
                this.agriForm.abonne=item.abonne,
                this.agriForm.banque_id=item.banque_id,
                this.agriForm.centre_id=item.centre_id

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.agriForm.id=item.id,
                this.agriForm.cin=item.cin,
                this.agriForm.nom=item.nom,
                this.agriForm.tel=item.tel,
                this.agriForm.quota=item.quota,
                this.agriForm.adresse=item.adresse,
                this.agriForm.rib=item.rib,
                this.agriForm.banque_id=item.banque_id,
                this.agriForm.centre_id=item.centre_id



                this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

      },



        click_AgriList: function () {
                        if(this.selected_centre)
                        {
                                            this.isLoading=true;
                                            this.$http.get('/agriList/'+this.selected_centre)
                                            .then((result) => {
                                                        this.agris = result.data.data
                                                        this.isLoading=false;

                                                    
                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                                        })
                                            });
                        }
                        else
                        {
                                        this.flashMessage.show({
                                        status: 'info',
                                        title: 'Sélectionez un centre et Reésayez !!',
                                        })
                        }
        },
       
       
        fetchFormBanqs: function () {
                    this.isLoading=true;
                    this.$http.get('/banques')
                    .then((result) => {
                                this.banqs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les centres:
                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
       
       handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.banquestore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>