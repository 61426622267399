<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-folder-open"></span> Suivi Des Agriculteurs</h4> 
            
        </div>

<div class="row card-body p-0 m-2 h-100">
  
        <div class="col-4 h-100 m-0 p-0">

               <div class="card border-info h-100 overflow-auto">


        <form @submit.prevent="handleSubmitBonCMD()" class="bg-info">
<div class="input-group  border border-info rounded bg-info p-1 col">
                                            <select class="form-control border border-info BgSearch " id="mois" v-model="itemFormBon.mois"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.mois.$error }">
                                            <option value=1>Janvier</option>
                                            <option value=2>Février</option>
                                            <option value=3>Mars</option>
                                            <option value=4>Avril</option>
                                            <option value=5>Mai</option>
                                            <option value=6>Juin</option>
                                            <option value=7>Juillet</option>
                                            <option value=8>Août</option>
                                            <option value=9>Septembre</option>
                                            <option value=10>Octobre</option>
                                            <option value=11>Novembre</option>
                                            <option value=12>Décembre</option>
                                            </select>
                <div v-if="isSubmitted && !$v.itemFormBon.mois.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
</div>


                <div class="input-group  border border-info rounded bg-info p-1 col">
                                            <select class="form-control border border-info BgSearch" id="centre_id" v-model="itemFormBon.centre_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.centre_id.$error }">
                                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>


                                    <div class="input-group-prepend">
                                        <button class="btn  btn-light BgSearch border border-info rounded-right ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                    
                <div v-if="isSubmitted && !$v.itemFormBon.centre_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                </div>
        </form>



                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchAgri"  class="border-info " id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par CIN</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filteragrie" :key="item.id" button class="p-3" variant="info" @click="agriculteur_click(item.id)">
                                 {{ item.nom }} -  {{ item.cin }}





                            </b-list-group-item>                          

                            </b-list-group>
               </div>
        </div> 


            <!-- LA PARTIE DES CARDES -->
        <div v-if="itemFormBon.agriculteur_id" class="col-8  mr-auto ">


          <div class="row mb-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">



                <div class="float-left pt-1 text-secondary"> 
                    <h5 class="font-weight-normal ml-2">   {{itemFormBon.agriculteur_nom}}-{{itemFormBon.agriculteur_nomAR}}-  <span  class="h6 font-weight-light text-danger">{{Uiabonne}}</span>  - {{itemFormBon.mois_nom}}</h5>
                </div>





                <b-button v-if="paiemAgri.id" pill size="sm"  class="m-1 float-right" variant="info" @click="print_click">
                            <i class="fa fa-print"></i>
                    <span> Impr.</span> 
                </b-button>

                <b-button v-if="!paiemAgri.id" pill size="sm"  class="m-1 float-right" variant="success" @click="click_payer">
                            <i class="fa fa-check"></i>
                    <span> Valider</span> 
                </b-button>

                <b-button v-if="paiemAgri.id" pill size="sm"  class="m-1 float-right" variant="secondary" @click="click_annulPaiem">
                            <i class="fa fa-eraser"></i>
                    <span> Annuler</span> 
                </b-button>


                <b-button pill size="sm"  class="m-1 float-right" variant="info" @click="fetchListPaiems">
                            <i class="fa fa-coins"></i>
                    <span> Historique</span> 
                </b-button>

                </div>
              </div>
            </div>

          </div>

<div v-if="!paiemAgri.id">
            <!-- LES CARTES DES CARTES -->
          <div class="row">

            <!-- Tasks Card Example -->
            <div class="col">
              <div class="card border-left-info shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">

                      <div class="border border-info rounded-pill mb-4">
                                  <div class=" d-flex justify-content-center pt-2 text-info">
                                          <h6 >LAIT</h6> 
                                  </div>
                      </div>


                        <div class=" d-flex justify-content-center p-1 mb-3 text-danger">
                                <h5 class="font-weight-normal">Total : {{totauxAgri.total_totalLaitDH}} DH</h5>
                        </div>



                      <div class="row no-gutters align-items-center">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Quota </span> : {{totauxAgri.total_quota}} L</div>
                      </div>

                      <div class="row no-gutters align-items-center">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Sur Quota </span> : {{totauxAgri.total_surquota}} L</div>
                      </div>


                      <div class="row no-gutters align-items-center mt-4">
                          <div class="h6 mb-2  text-secondary"><span class="d-inline font-weight-light"> Total Quota </span> : {{totauxAgri.total_quotaDH}} DH</div>
                      </div>

                      <div class="row no-gutters align-items-center">
                          <div class="h6 mb-2  text-secondary"><span class="d-inline font-weight-light"> Total Sur Quota </span> : {{totauxAgri.total_surquotaDH}} DH</div>
                      </div>





                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">
                      <div class="border border-info rounded-pill mb-4">
                                  <div class=" d-flex justify-content-center text-success">
                                          <h6 class=" mt-2" >SERVICES / AVANCES</h6>


                                  </div>
                      </div>



                        <div class=" d-flex justify-content-center p-1 text-danger mb-3">
                                <h5 class="font-weight-normal">Total : {{totauxAgri.total_serv_avances}} DH</h5>
                        </div>

                      <div class="row no-gutters align-items-center">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Total Services </span> : {{totauxAgri.total_s}} DH</div>
                      </div>

                      <div class="row no-gutters align-items-center">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Total Avances </span> : {{totauxAgri.total_avances}} DH</div>
                      </div>



                       <div class=" d-flex justify-content-center p-1 mt-4">
                                        <b-button  size="sm"  class="d-inline p-0 text-info" variant="link" @click="click_show_selct_dep">
                                                    <i class="fa fa-edit"></i>
                                            <span> Afficher / Modifier Les Services</span> 
                                        </b-button>

                      </div>

                      <div class=" d-flex justify-content-center p-1">



                                        <b-button   size="sm"  class="d-inline p-1 text-primary" variant="link" @click="click_avance">
                                                    <i class="fa fa-plus-square"></i>
                                            <span> Nouvelle Avance </span> 
                                        </b-button>

                                        <b-button   size="sm" class="ml-3 d-inline p-1 text-info" variant="link" @click="fetchAvancesAgris">
                                                    <i class="fa fa-clipboard-list"></i>
                                            <span> Liste Des Avances </span> 
                                        </b-button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
        
  <!-- Le pred de page  -->
           <div class="row mt-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-secondary">
                                <h4 class="font-weight-normal">Solde Du Mois : {{totalNet}} DH</h4>
                        </div>

                </div>
              </div>
            </div>

          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-danger">
                                <h4 class="font-weight-normal">Ancien Crédit : {{totalAncien}} DH</h4>
                        </div>

                </div>
              </div>
            </div>

          </div>
        

          <div class="row mt-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-success">
                                <h4 class="font-weight-normal">Montant à Payer : {{totalPayer}} DH</h4>
                        </div>

                </div>
              </div>
            </div>

          </div>
</div>

          <div class="row mt-3" v-if="paiemAgri.id">
            <div class="col">
              <div class="card border-left-info shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">

                      <div class="border border-info rounded-pill mb-4">
                                  <div class=" d-flex justify-content-center pt-2 text-info">
                                          <h6 >Mois Validé Le {{paiemAgri.date}}</h6> 
                                  </div>
                      </div>


                        <div class=" d-flex justify-content-center p-1 mb-3 text-danger">
                                <h5 class="font-weight-normal">Net Payé : {{paiemAgri.total_net}} DH</h5>
                        </div>

                      <div class="row no-gutters align-items-center">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Total Lait </span> : {{paiemAgri.total_lait}} DH</div>
                      </div>

                      <div class="row no-gutters align-items-center mt-4">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Total Services /Avances </span> : {{paiemAgri.total_service}} DH</div>
                      </div>

                      <div class="row no-gutters align-items-center mt-4">
                          <div class="h6 mb-2  text-secondary "><span class="d-inline font-weight-light">Ancien Crèdit </span> : {{totalAncienUI}} DH</div>
                      </div>


                      <div class="row no-gutters align-items-center mt-4">
                          <div class="h6 mb-2  text-secondary"><span class="d-inline font-weight-light"> Par l'utilisateur </span> : {{paiemAgri.user}}</div>
                      </div>







                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>















        
        </div>   


</div> 





    <!-- le modal -->
    <b-modal id="banqModal" :title=itemFormBon.agriculteur_nom hide-footer>
        <form @submit.prevent="handleSubmit">

        <div class="mt-1 ml-2  text-secondary font-weight-light">Mois : 
          <span class="d-inline text-info"> {{paiemAgri.n_mois}}</span>  </div>

          <div class="mt-3 ml-2  text-secondary font-weight-light">Date : 
          <span class="d-inline text-info"> {{paiemAgri.date}} </span>  </div>
        
          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Laits (DH) : 
          <span class="d-inline text-info"> {{paiemAgri.total_lait}} </span>  </div>

          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Services /Avances (DH) : 
          <span class="d-inline text-info"> {{totauxAgri.total_serv_avances}} </span>  </div>

          <div class="mt-3 ml-2 text-secondary font-weight-light">Total NET (DH) : 
          <span class="d-inline text-info"> {{paiemAgri.total_net}} </span>  </div>


                <div v-if="paiemAgri.total_net > 0" class="form-group mt-3">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="paiemAgri.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">Valider</button>
            </div>



        </form>
    </b-modal>


    <!-- le modal -->
    <b-modal id="comptModal" title="Sélectionnez un compte"  hide-footer>
            <form @submit.prevent="handleSubmitCmp">
                            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="paiemAgri.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>
            </form>  

    </b-modal>

    <!-- le modal -->
    <b-modal id="detailPaiement" :title=itemFormBon.agriculteur_nom size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsPaiements" 
        :items="PaiementsList"
        >

        
        </b-table>

    </b-modal>




    <!-- le modal -->
    <b-modal id="avancModal" title="Avance"  hide-footer>
           <form @submit.prevent="handleSubmitAvance">

            <div class="form-group">
                <label for="date">Date :</label>
                    <b-form-datepicker
                    id="datepicker-dateformat2"
                    placeholder="Choose a date"
                    v-model="avanceAgri.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />

            </div>

            <div class="form-group">
                <label for="quota">Montant :</label>
                <input type="text" v-model="avanceAgri.montant" id="quota" name="quota" class="form-control"/>

            </div>


            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="avanceAgri.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>


        </form>
    </b-modal>


    <!-- le modal -->

 <b-modal  id="showAvancesmodal" title="Avances"  hide-footer>



        <b-table 
        sort-icon-left
        small 
        sticky-header
        :fields="fieldsavances" 
        :items="AvancesList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="click_annulAvence(row.item.id)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


</b-modal>






    <!-- le modal -->
    <b-modal id="depotModal" title="Sélectionnez Le Dépôt"  hide-footer>
           <form @submit.prevent="click_AddService">


                                

            <div class="form-group">
                            <label for="depot_id">Dépôt :</label>
                            <select class="form-control" id="depot_id" v-model="itemFormBon.depot_id">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block"> Afficher / Modifier Les Services</button>
            </div>


        </form>
    </b-modal>


    <!-- le modal -->





    <!-- les trucs public -->
    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {

computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    filteragrie() {
            return this.agriculteursList.filter(agrie => {

            if(this.isCINBarreSearch)
            {
                if(agrie.cin)
                {
                return agrie.cin.toLowerCase().includes(this.serchAgri.toLowerCase())
                }

            }
            else
            {

                if(agrie.nom)
                {
                return agrie.nom.toLowerCase().includes(this.serchAgri.toLowerCase())
                }


            }

      })
    }
    },
    mounted(){

                this.GetPrixLait()

                this.fetchCentreDep()
                //current month
                let mm= new Date().getMonth()
                mm++
                this.itemFormBon.mois = mm


                this.fetchFormComptes()

    },
    data () {
		return {
            fieldsavances: [

                {key: 'date', label: 'Date', sortable: true},
                {key: 'montant', label: 'Montant', sortable: true},
                { key: 'actions', label: '' }

            ],
            AvancesList:[],

            avanceAgri: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    mois:0,
                    montant:0,
                    agriculteur_id:'',
                    bank_compte_id:'',
                    user:'user',

            },
            fieldsPaiements: [ 

                {key: 'n_mois', label: 'Mois', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'total_lait', label: 'Lait', sortable: true},
                {key: 'total_service', label: 'Services/Avances', sortable: true},
                {key: 'ancien_credit', label: 'Ancien Crédit', sortable: true},
                {key: 'total_net', label: 'Net', sortable: true},
            ],
            PaiementsList:[],

            comptes:[],
            paiemAgri: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    n_mois:'mois',
                    mois:0,
                    total_lait:0,
                    total_service:0,
                    total_net:0,
                    user:'user',
                    agriculteur_id:'',
                    centre_id:'',
                    bank_compte_id:''

            },

            totauxAgri: {
                    total_quota:'0',
                    total_surquota:'0',
                    total_s:'0',
                    total_quotaDH:'0',
                    total_surquotaDH:'0',
                    total_totalLaitDH:'0',
                    prixquota:0,
                    prixsurquota:0,
                    prixsurquotaabon:0,
                    total_avances:0,
                    total_serv_avances:0,

            },
            totalNet:'0',
            totalAncien:'0',
            totalAncienUI:'0',
            totalPayer:'0',
            itemFormBon: {
                    centre_id:'',
                    centre_nom:'',
                    depot_id:'',
                    depot_nom:'',
                    agriculteur_id:'',
                    agriculteur_nom:'',
                    agriculteur_nomAR:'',
                    mois:'',
                    mois_nom:'',
                    mois_nomAR:'',
                    abon:0,
                    cin:'',
            },

            centres:[],
            depots:[],
            agriculteursList:[],
            isSubmitted: false,
            isLoading: false,
            isCINBarreSearch: false,
            serchAgri:'',
            Uiabonne:'',
            itemslistBnServ: [],
            isAnnulPaiem: true,

		}
    },
    validations: {
            itemFormBon: {
                centre_id: {
                    required
                },
                mois: {
                    required
                }

            }

    },
 methods:{



        fetchAvancesAgris: function () {
            //comptes : 
                    this.isLoading=true;
                    this.$http.get('/avanceAgriculteur/'+this.itemFormBon.agriculteur_id+"/"+this.itemFormBon.mois)
                    .then((result) => {
                                this.AvancesList = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'showAvancesmodal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        click_avance()
        {


            this.avanceAgri.mois = this.itemFormBon.mois
            this.avanceAgri.agriculteur_id = this.itemFormBon.agriculteur_id
            this.avanceAgri.bank_compte_id = ''
            this.avanceAgri.montant = 0

            this.$root.$emit('bv::show::modal', 'avancModal')
        },
        handleSubmitAvance() {
              if(!this.avanceAgri.bank_compte_id && !this.avanceAgri.montant)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte et entrez un montant valide !!',
                })   
              }
              else
              {

                  this.storeAvance()


              }


        },
        storeAvance(){
            //verifier le compte :
            this.avanceAgri.user= this.user_name

            this.isLoading=true;

            this.$http.post('/agriAvances',{
                    date:this.avanceAgri.date,
                    mois:this.avanceAgri.mois,
                    montant:this.avanceAgri.montant,
                    agriculteur_id:this.avanceAgri.agriculteur_id,
                    bank_compte_id:this.avanceAgri.bank_compte_id,
                    user:this.avanceAgri.user

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data.data)
                    //get id of paiement



                    //recharger les tauteaux
                    this.TotauxAgriculteurs(this.itemFormBon.agriculteur_id,this.itemFormBon.mois,this.itemFormBon.abon)

                    this.$bvModal.hide('avancModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        print_click: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/getBonServicParAgri/'+this.itemFormBon.agriculteur_id+'/'+this.itemFormBon.mois)
                    .then((result) => {
                                this.itemslistBnServ = result.data.data
                                this.isLoading=false;

                                this.exportDox()

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        exportDox()
        {

                        let mm=this.centres.findIndex(item => item.id === this.itemFormBon.centre_id)

                        let cent= this.centres[mm].nom




        let tototL_lait =   (Number(this.totauxAgri.total_quota) + Number(this.totauxAgri.total_surquota)).toFixed(2)



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "المجموع",
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "الثمن",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "الكمية",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        //داخل الكوطة
        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+this.totauxAgri.total_quotaDH.toString(),
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.totauxAgri.prixquota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.totauxAgri.total_quota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "داخل الكوطا",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )

        //فوق الكوطة
        r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+this.totauxAgri.total_surquotaDH,
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.totauxAgri.prixsurquota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.totauxAgri.total_surquota.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "فوق الكوطا",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )

        //les tauteaooo
        r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+this.totauxAgri.total_totalLaitDH,
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "المستحق",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: ""+tototL_lait,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "مجموع الكمية ",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )


                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })


//listes des services:

                        let datrwServ =[new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "المجموع بالدرهم",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "مجموع الكمية",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "الخدمة",
                                                            spacing: {
                                                                before: 100,
                                                                after: 100
                                                            },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),



                                    ],
                                })]

                        this.itemslistBnServ.forEach(function(item){

                        let rr = new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.total_dh.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.total_qte.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.nom,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })

                        datrwServ.push(
                        rr
                        )
                        });

//total services :
                        let rrtotal = new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 4000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "المجموع ",
                                color: '636572',
                                bold: true,
                            }),

                            new TextRun({
                                text:  this.totauxAgri.total_s+" : ",
                                color: '636572',
                                bold: true,
                            }),


                            
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),],
                                        }),

                                    ]
                                })


                        datrwServ.push(
                        rrtotal
                        )


                        const tableserv = new Table({

                            rows:  datrwServ
                        })





//fin des liste des  services





                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({

                            bidirectional: true,

                            children: [

                                new TextRun({
                                text: "وصل الأداء لشهر :",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                                new TextRun({
                                text: this.itemFormBon.mois_nomAR,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({

                            bidirectional: true,

                            children: [

                                new TextRun({
                                text: "السيد/السيدة",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            new TextRun({
                                text: " : "+this.itemFormBon.agriculteur_nomAR ,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "Centre : " + cent,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "    CIN : " + this.itemFormBon.cin,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "       "+this.itemFormBon.agriculteur_nom+"      ",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })

                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "الحليب",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 200
                                },
                        }),

                        table,


                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "الخدمات",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 200
                                },
                        }),


                        tableserv,


                        new Paragraph({

                            bidirectional: true,

                            children: [





                            new TextRun({
                                text: "مجموع التسبيق"  ,
                                color: '636572',
                                bold: true,
                            }),

                            new TextRun({
                                text:  this.totauxAgri.total_avances +" : ",
                                color: '636572',
                                bold: true,
                            }),
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.LEFT,
                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                                        new Paragraph({

                            bidirectional: true,

                            children: [





                            new TextRun({
                                text: "الدين القديم"  ,
                                color: '636572',
                                bold: true,
                            }),

                            new TextRun({
                                text:  this.totalAncienUI +" : ",
                                color: '636572',
                                bold: true,
                            }),
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.LEFT,
                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),

                        

                        new Paragraph({

                            bidirectional: true,

                            children: [

                            new TextRun({
                                text: "المستحق الصافي بالدرهم",
                                color: 'db8932',
                                bold: true,
                                font: "Segoe UI"
                            }),

                            new TextRun({
                                text: this.paiemAgri.total_net+" : ",
                                color: 'db8932',
                                bold: true,
                                font: "Segoe UI"
                            }),

                            
                            
                            ],

                        
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 300
                                },
                        }),

                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, this.itemFormBon.agriculteur_nom+"_reçu.docx");

            });

        },
        GetPrixLait()
        {
                        this.isLoading=true;
                        this.$http.get('/pametres/1')
                        .then((result) => {


                            this.totauxAgri.prixquota=result.data.qota_p
                            this.totauxAgri.prixsurquotaabon=result.data.sur_quota_abonp
                            this.totauxAgri.prixsurquota=result.data.sur_quota_p

                            this.isLoading=false;

                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                    })
                        });
        },
        fetchFormComptes: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchCentreDep: function () {



                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchListAgriculteurs(this.itemFormBon.centre_id)
   

                }


        },
        fetchListAgriculteurs(id_agri) {
                    this.isLoading=true;
                    this.$http.get('/agriCentreMin/'+id_agri)
                    .then((result) => {
                                this.agriculteursList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        agriculteur_click(id_agri)
        {

            let agr = this.agriculteursList.findIndex(item => item.id === id_agri)
            this.itemFormBon.agriculteur_id=this.agriculteursList[agr].id
            this.itemFormBon.abon=this.agriculteursList[agr].abonne

            this.itemFormBon.cin=this.agriculteursList[agr].cin
            this.itemFormBon.agriculteur_nom=this.agriculteursList[agr].nom
            this.itemFormBon.agriculteur_nomAR=this.agriculteursList[agr].nom_ar

                if(this.itemFormBon.abon==1)
                {
                this.Uiabonne="(Adhérent)"
                }
                else
                {
                this.Uiabonne=""
                }






            this.verifier_paiement(this.itemFormBon.agriculteur_id,this.itemFormBon.mois)

            this.TotauxAgriculteurs(this.itemFormBon.agriculteur_id,this.itemFormBon.mois,this.itemFormBon.abon)


            //get name: 
            this.getMoisName(this.itemFormBon.mois)


        },       
        TotauxAgriculteurs(id_agri,lmois,abonn) {
                    this.isLoading=true;
                    this.$http.get('/agriTotaux/'+id_agri+'/'+lmois)
                    .then((result) => {


                                this.totauxAgri.total_quota = result.data.data_lait[0].total_quota
                                this.totauxAgri.total_surquota = result.data.data_lait[0].total_surquota
                                this.totauxAgri.total_s = result.data.data_serv[0].total_s
                                this.totalAncien = result.data.data_ancien
                                this.totauxAgri.total_avances=result.data.total_avances[0].total_avances


                                    if(!this.totauxAgri.total_avances)
                                   {
                                    this.totauxAgri.total_avances=0
                                    }
                                    else
                                    {
                                      let number =Number(this.totauxAgri.total_avances)
                                      this.totauxAgri.total_avances = number.toFixed(2)
                                    }


                                    if(!this.totauxAgri.total_s)
                                    {
                                    this.totauxAgri.total_s=0
                                    }
                                    else
                                    {
                                      let number =Number(this.totauxAgri.total_s)
                                      this.totauxAgri.total_s = number.toFixed(2)
                                    }


                                    if(!this.totauxAgri.total_quota)
                                    {
                                    this.totauxAgri.total_quota = 0
                                    }

                                    if(!this.totauxAgri.total_surquota)
                                    {
                                    this.totauxAgri.total_surquota = 0
                                    }

                                    if(!this.totalAncien)
                                    {
                                    this.totalAncien = 0
                                    }
                                    else
                                    {
                                                if(this.totalAncien<0)
                                                {
                                                this.totalAncien= Number(this.totalAncien*-1).toFixed(2)
                                                }
                                                else
                                                {
                                                this.totalAncien = 0
                                                }
                                    }

                                    //calcul

                                    if(abonn==1)
                                    {
                                    this.totauxAgri.total_quotaDH = Number(this.totauxAgri.total_quota*this.totauxAgri.prixquota).toFixed(2)
                                    this.totauxAgri.total_surquotaDH = Number(this.totauxAgri.total_surquota*this.totauxAgri.prixsurquotaabon).toFixed(2)
                                    }
                                    else
                                    {

                                    this.totauxAgri.total_quotaDH = Number(this.totauxAgri.total_quota*this.totauxAgri.prixquota).toFixed(2)
                                    this.totauxAgri.total_surquotaDH = Number(this.totauxAgri.total_surquota*this.totauxAgri.prixsurquota).toFixed(2)
                                    }


                                    this.totauxAgri.total_totalLaitDH = Number(this.totauxAgri.total_quotaDH) + Number(this.totauxAgri.total_surquotaDH)

                                    this.totalNet =Number(this.totauxAgri.total_totalLaitDH - this.totauxAgri.total_s - this.totauxAgri.total_avances).toFixed(2) 


                                    this.totalPayer =   Number(this.totalNet - this.totalAncien).toFixed(2)


                                    this.totauxAgri.total_serv_avances = Number(this.totauxAgri.total_avances)+Number(this.totauxAgri.total_s)

                                    //fin calcul effacer loader
                                    this.isLoading=false;


                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        click_show_selct_dep()
        {

        this.itemFormBon.depot_id=''

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depots')
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;


                                this.$root.$emit('bv::show::modal', 'depotModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        click_AddService()
        {
          
              if(!this.itemFormBon.depot_id)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un dépôt !!',
                })   
              }
              else
              {

                            //le nom du depot
                            let ag = this.depots.findIndex(item => item.id === this.itemFormBon.depot_id)
                            this.itemFormBon.depot_nom=this.depots[ag].nom


                            //le nom du centre : centre_nom 
                            let agr = this.centres.findIndex(item => item.id === this.itemFormBon.centre_id)
                            this.itemFormBon.centre_nom=this.centres[agr].nom


                                this.$router.push({ 
                                        name: 'servicesAgriDetail', 
                                        params: { 
                                        idAgri: this.itemFormBon.agriculteur_id,
                                        nAgri:this.itemFormBon.agriculteur_nom,
                                        idCentre: this.itemFormBon.centre_id,
                                        idDepot: this.itemFormBon.depot_id,
                                        nDepot: this.itemFormBon.depot_nom,
                                        nCentre: this.itemFormBon.centre_nom,
                                        mois: this.itemFormBon.mois,
                                        nMois: this.itemFormBon.mois_nom
                                        } 
                                    });


              }








        },
        getMoisName(mm)
        {
                        if (mm==1)
                        {
                        this.itemFormBon.mois_nom='Janvier'

                        this.itemFormBon.mois_nomAR='يناير'
                        }
                        else if (mm==2)
                        {
                        this.itemFormBon.mois_nom='Février'
                        this.itemFormBon.mois_nomAR='فبراير'
                        }
                        else if (mm==3)
                        {
                        this.itemFormBon.mois_nom='Mars'

                        this.itemFormBon.mois_nomAR='مارس'
                        }
                        else if (mm==4)
                        {
                        this.itemFormBon.mois_nom='Avril'

                        this.itemFormBon.mois_nomAR='ابريل'
                        }
                        else if (mm==5)
                        {
                        this.itemFormBon.mois_nom='Mai'

                        this.itemFormBon.mois_nomAR='ماي'
                        }
                        else if (mm==6)
                        {
                        this.itemFormBon.mois_nom='Juin'

                        this.itemFormBon.mois_nomAR='يونيو'
                        }
                        else if (mm==7)
                        {
                        this.itemFormBon.mois_nom='Juillet'

                        this.itemFormBon.mois_nomAR='يليوز'
                        }
                        else if (mm==8)
                        {
                        this.itemFormBon.mois_nom='Août'

                        this.itemFormBon.mois_nomAR='غشت'
                        }
                        else if (mm==9)
                        {
                        this.itemFormBon.mois_nom='Septembre'

                        this.itemFormBon.mois_nomAR='شتنبر'
                        }
                        else if (mm==10)
                        {
                        this.itemFormBon.mois_nom='Octobre'

                        this.itemFormBon.mois_nomAR='اكتوبر'
                        }
                        else if (mm==11)
                        {
                        this.itemFormBon.mois_nom='Novembre'

                        this.itemFormBon.mois_nomAR='نونبر'
                        }
                        else if (mm==12)
                        {
                        this.itemFormBon.mois_nom='Décembre'

                        this.itemFormBon.mois_nomAR='دجنبر'
                        }
                        else
                        {
                        this.itemFormBon.mois_nom='--';
                        this.itemFormBon.mois_nomAR='--'
                        }

        },
        click_payer()
        {

            this.paiemAgri.n_mois = this.itemFormBon.mois_nom
            this.paiemAgri.mois = this.itemFormBon.mois
            this.paiemAgri.total_lait = this.totauxAgri.total_totalLaitDH
            this.paiemAgri.total_service = (Number(this.totauxAgri.total_s) + Number(this.totalAncien))
            this.paiemAgri.total_net = Number(this.totalNet - this.totalAncien).toFixed(2);
            this.paiemAgri.agriculteur_id = this.itemFormBon.agriculteur_id
            this.paiemAgri.centre_id= this.itemFormBon.centre_id

            //verifier le compte :
            if(this.paiemAgri.total_net<= 0)
            {
            this.paiemAgri.bank_compte_id = 0
            }
            else
            {
                this.paiemAgri.bank_compte_id = ''
            }

            this.$root.$emit('bv::show::modal', 'banqModal')
        },
        handleSubmit() {
              if(!this.paiemAgri.bank_compte_id && this.paiemAgri.total_net > 0)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })   
              }
              else
              {

                  this.storePaiement()


              }


        },
        storePaiement(){
            //verifier le compte :
            if(this.paiemAgri.total_net<= 0)
            {
            this.paiemAgri.bank_compte_id = 0
            }

            this.paiemAgri.user= this.user_name

            this.isLoading=true;

            this.$http.post('/paiemAgris',{
                    date:this.paiemAgri.date,
                    n_mois:this.paiemAgri.n_mois,
                    mois:this.paiemAgri.mois,
                    total_lait:this.paiemAgri.total_lait,
                    total_service:this.totauxAgri.total_serv_avances,
                    ancien_credit:this.totalAncien,
                    total_net:this.paiemAgri.total_net,
                    user:this.paiemAgri.user,
                    agriculteur_id:this.paiemAgri.agriculteur_id,
                    centre_id:this.paiemAgri.centre_id,
                    bank_compte_id:this.paiemAgri.bank_compte_id

                })
                .then(response => {

                    this.isLoading=false;
                    //get id of paiement
                                        this.paiemAgri.id=response.data.data.id

                                        this.paiemAgri.total_lait = response.data.data.total_lait 
                                        this.paiemAgri.total_service = response.data.data.total_service
                                        
                                        this.totalAncienUI = response.data.data.ancien_credit
                                        
                                        this.paiemAgri.total_net = response.data.data.total_net 

                                        this.paiemAgri.date = response.data.data.date 
                                        this.paiemAgri.user = response.data.data.user 


                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        click_annulPaiem() {

                this.$confirm("Voulez-vous vraiment Annuler ce paiement ??")
                 .then(() => {


                    //marker le type de select compt:
                    this.isAnnulPaiem= true


                    this.paiemAgri.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')
                    this.paiemAgri.bank_compte_id=''



                    if(this.paiemAgri.total_net <=0)
                    {
                    this.paiemAgri.bank_compte_id=0
                    //annuler 
                    this.annulPaiem()
                    }    
                    else
                    {
                    this.paiemAgri.bank_compte_id=''
                    this.$root.$emit('bv::show::modal', 'comptModal')
                    }

                    })
                                .catch(error=> {
                                console.log(error)

                    });




        },
        annulPaiem()
        {
                //USER HANDLE
                this.paiemAgri.user= this.user_name


            this.isLoading=true;

            this.$http.get('/annulerPaiemAgr/'+this.paiemAgri.id+'/'+this.paiemAgri.bank_compte_id+'/'+this.paiemAgri.date+'/'+this.paiemAgri.user)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                      this.paiemAgri.id=''


                                          //recharger les tauteaux
                    this.TotauxAgriculteurs(this.itemFormBon.agriculteur_id,this.itemFormBon.mois,this.itemFormBon.abon)

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
        },   
        click_annulAvence(idp) {

                this.$confirm("Voulez-vous vraiment Annuler cet Avance ??")
                 .then(() => {


                    //marker le type de select compt:
                    this.isAnnulPaiem= false

                    this.avanceAgri.id=idp,
                    this.paiemAgri.bank_compte_id=''
                    this.avanceAgri.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')

                    this.$root.$emit('bv::show::modal', 'comptModal')

                    })
                                .catch(error=> {
                                console.log(error)

                    });




        },
        annulavance()
        {
                //USER HANDLE
                this.avanceAgri.user= this.user_name


                this.isLoading=true;

                this.$http.get('/annulerAvanceAgr/'+this.avanceAgri.id+'/'+this.paiemAgri.bank_compte_id+'/'+this.avanceAgri.date+'/'+this.avanceAgri.user)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                let index = this.AvancesList.findIndex(item => item.id === this.avanceAgri.id)
                this.AvancesList.splice(index, 1)


                    //recharger les tauteaux
                    this.TotauxAgriculteurs(this.itemFormBon.agriculteur_id,this.itemFormBon.mois,this.itemFormBon.abon)


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
        },
        verifier_paiement(id_agri,lmois)
        {
                      this.isLoading=true;
                      this.$http.get('/verifierPaiem/'+id_agri+'/'+lmois)
                      .then((result) => {

                      this.isLoading=false;

                                        this.paiemAgri.id = result.data.data.id
                                        this.paiemAgri.total_lait = result.data.data.total_lait 
                                        this.paiemAgri.total_service = result.data.data.total_service 
                                        this.totalAncienUI = result.data.data.ancien_credit
                                        this.paiemAgri.total_net = result.data.data.total_net 

                                        this.paiemAgri.date = result.data.data.date 
                                        this.paiemAgri.user = result.data.data.user 


                      })
                      .catch(error=> {



                                  console.log(error)
                                  this.isLoading=false;
                                  this.paiemAgri.id=''
                      });
        },
        handleSubmitCmp() {

            if(this.paiemAgri.bank_compte_id)
            {

                    if(this.isAnnulPaiem== true)
                    {
                                    this.annulPaiem()

                                    this.$bvModal.hide('comptModal')
                    }
                    else
                    {

                                    this.annulavance()

                                    this.$bvModal.hide('comptModal')

                    }

            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })

            }
        },
        fetchListPaiems()
        {
          
                    this.isLoading=true;
                    this.$http.get('/paimentsAgriculteur/'+this.itemFormBon.agriculteur_id)
                    .then((result) => {
                                this.PaiementsList = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'detailPaiement')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });


        },




},
components: {
              Loading      
}


}
</script>

<style>
.Pgheader {
  background-color: blue;
  opacity: 0.3;
}
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}

</style>