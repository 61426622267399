<template>
  

<div  class="rounded-lg shadow border border-white bg-white h-100  pb-5">



        <div class="row pb-1 border-bottom border-info m-3">


                            <h4 class="col  mt-3  font-weight-lighter text-info"> <span class="fa fa-prescription-bottle-alt"></span> Collection Du Lait     </h4> 
            
        </div>

          <div class="row mt-5 mb-5">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmit()">

            <div class="row mt-2">


                         <div class="form-group col">

                            <label for="centre_id">Centre :</label>
                            <select class="form-control" id="centre_id" v-model="itemForm.centre_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.centre_id.$error }">
                            <option v-for="item in centres" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemForm.centre_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>
            </div>





            <div class="row  mt-2">

            <div class="form-group col">
                <label for="dateDebut" class="mr-sm-2">Date Début :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-dateDebut"
                    placeholder="date debut"
                    v-model="itemForm.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            <div class="form-group col">
                <label for="dateFin" class="mr-sm-2">Date Fin :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemForm.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>


            <div class="custom-control custom-switch mb-4">
            <input type="checkbox"
             class="custom-control-input" 
             id="matin"
                    v-model="itemForm.matin"
                    name="matin"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="matin">Matin</label>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
            </div>

        </form>

        </div>   

</div> 

    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {

mounted(){

this.fetchCentres()


    },
data () {
		return {

            centres:[],
            itemForm: {
                    centre_id:'',
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    matin:true,
            },
            isSubmitted: false,
            isLoading: false,

		}
    },
    validations: {
            itemForm: {
                centre_id: {
                    required
                },
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                },
                matin: {
                    required
                }
            }

    },
methods:{


        fetchCentres: function () {

                    //chager les centres:
                    this.isLoading=true;
                    this.$http.get('/centres')
                    .then((result) => {
                                this.centres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                                        
                            if(this.itemForm.dateDebut<=this.itemForm.dateFin)
                            {

                            let agr = this.centres.findIndex(item => item.id === this.itemForm.centre_id)

                                            this.$router.push({ 
                                            name: 'agriLaitAddDetail', 
                                            params: { 
                                            idCentre: this.itemForm.centre_id,
                                            dateDebutL: this.itemForm.dateDebut,
                                            dateFinL:this.itemForm.dateFin,
                                            matin:this.itemForm.matin,
                                            nCentre: this.centres[agr].nom
                                            } 
                                                }); 
                            }
                            else
                            {
                                this.flashMessage.show({
                                status: 'warning',
                                title: 'Vérifiez Les Dates',
                                message: 'Entrez des dates valides.. Actualisez et Réessayez'
                                })
                            }
                }


        },

       


        



},
components: {
              Loading      
}


}
</script>

<style>

</style>